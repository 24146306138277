import React, { useContext } from "react";
import PreislandingLayout, {
  ProductListContext,
} from "../../layouts/PreislandingLayout";

import ProductsTable from "../../components/products-table/ProductsTable";
import PriceComparisonBanner from "../../components/price-comparison-banner/PriceComparisonBanner";
import PriceFAQ from "../../components/price-faq/PriceFAQ";
import SEOComponent from "../../components/Shared/Seo";

const AluminiumpreisPage = () => {
  const { Consumer } = ProductListContext;
  return (
    <PreislandingLayout>
      <SEOComponent page="aluminiumpreis" />

      <Consumer>
        {value => (
          <ProductsTable
            productList={value.productList}
            categoryId={75}
            name="Aluminiumpreis"
          />
        )}
      </Consumer>
      <PriceComparisonBanner />
      <PriceFAQ page="aluminiumpreis" />
    </PreislandingLayout>
  );
};

export default AluminiumpreisPage;
